h2{margin:0px}
.rowHeader{
    margin-bottom:1rem; 
    padding-bottom:1rem; 
    border-bottom:1px solid #dedede;
}
.rowFooter{
    margin-top:1rem; 
    padding-top:1rem; 
    border-top:1px solid #dedede;
}