
.buildingName{
    font-size: 1.25rem;
    margin-bottom: 10px;
    padding-bottom: 15px;
    border-bottom: 3px solid #ccc;
    text-align:center;
    font-weight:600;
}
.tableHead{border-bottom: 3px solid #cccccc;}
.tableHead::after{
    content:'';
    height:15px;
    width:100%;
    display:block
}
.tableHead th{font-weight:600}

.tableBody td, tfoot td{font-size:14px; padding:10px 0px}
.tableBody tr, tfoot tr{border-bottom:1px solid #dedede}
.tableBody tr:last-child{border-bottom:0px}

tfoot tr:first-child{border-top: 3px solid #ccc;border-bottom:3px solid #ccc;}
tfoot tr:last-child{
    border-bottom:0px;
}