.imageWrap{display:block; position:relative;margin-bottom:1.5rem}
.btn:hover{cursor:pointer;}

.uploadBtnWrap{
    text-align:center;
    display:block;
    padding: 20px;
    border:1px solid rgba(0,0,0,.2);
    background-color:#f0f0f0;;
    font-weight:600;
    margin-bottom:1.5rem;
    color: #6c757d;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
.uploadBtn{
    padding:10px;
    width:100%;
    max-width:450px;
    border:1px dashed rgba(0,0,0,.2);
}
.removeBtn{
    position:absolute;
    right:0;
    background-color:transparent;
    border:0px;
    font-size:24px;
    color:#e3e3e3;
}
.dashedDivider{
    border-top: 2px dashed rgba(0,0,0,.2);
    padding-top:15px;
    margin-top:10px;
}
@media only screen and (min-width: 768px) {
    .dashedDivider{
        border-right: 2px dashed rgba(0,0,0,.2);
        border-top:0px;
        padding-top:0;
        margin-top:0;
    }
}
