body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#cecece;
}

.App{
  margin-bottom:30px;
}

a{
  text-decoration:none;
  color:#6c757d;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
a:hover{
  text-decoration:none;
  color:#6c757d;
  opacity:.75;
}

h2{
  font-size:1.25rem;
}
h3{
  font-size:1.15rem;
  margin-bottom: 0;
}

.h-100{height:100%}
.mb10{margin-bottom:10px}
.mb20{margin-bottom:20px}
.mb25{margin-bottom:25px}
.mb30{margin-bottom:30px}
.mb1rem{margin-bottom:1rem}
.mb1hrem{margin-bottom:1.5rem}
.mb2rem{margin-bottom:2rem}

.mt10{margin-top:10px}
.mt20{margin-top:20px}
.mt25{margin-top:25px}
.mt30{margin-top:30px}
.mtauto{margin-top:auto}
.mt1rem{margin-top:1rem}
.mt1hrem{margin-top:1.5rem}
.mt2rem{margin-top:2rem}

.text-left{text-align:left}
.text-right{text-align:right}
.text-center{text-align:center}

.text-bold{font-weight:bold}
.text-600{font-weight:600}
.text-italic{font-style:italic}

.flex-column{flex-direction:column}

.youtube-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.youtube-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.at-tjin-title{
  margin-bottom: 0px !important;
  text-align:center;
  line-height: 25px;
}
.at-tjin-title p{
  margin-bottom: 5px !important;
}
.at-tjin-btn{
  margin-bottom:0px !important;
}
.addthis_toolbox{
  max-width:145px;
  margin:0 auto;
}
.at-tjin-element .addthis_toolbox a:nth-child(4){
  margin-right:0px !important;
  margin:0px !important;
}
.addthis_top_donate{
  background-color: #f0f0f0;
  border: 1px solid rgba(0,0,0,.2);
  padding: 5px;
  animation-duration: .4s;
  animation-name: slideInRight;
  min-height: 80px;
}
.addthis_bottom_donate .at-tjin-title, .addthis_bottom_donate .at-tjin-title p span, .addthis_bottom_donate .at-tjin-title p{
  display:none !important;
  font-size:0px !important;
  margin:0px !important;
  height:0px;
}

.g-translator{
  text-align:right;
}
.g-translator #google_translate_element{
  display:inline-flex;
}
.g-translator .goog-te-gadget{
  font-size:0px;
}
.g-translator span{
  display:none;
}
.g-translator select{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  padding: 4px;
}

.modal-btn{
  background-color:transparent;
  border:0px;
  color: #6c757d;
  transition: all 0.25s ease;
  font-weight:600;
}

.light-gray-box{
  text-align: center;
  display: block;
  padding: 20px;
  border: 1px solid rgba(0,0,0,.2);
  background-color: #f0f0f0;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #6c757d;
  box-shadow: 0 2px 8px rgb(0 0 0 / 26%);
}

.total-title{
  padding-bottom:5px;
  margin-bottom:5px;
  text-align:center;
  color: #6c757d;
}

.port-total th{
  font-weight:600;
  border-bottom:1px solid rgba(0,0,0,.2)
}

.nav-tabs{
  border-bottom: 0px;
  max-width: 100%;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  width:calc(100% - 83.25px);
  overflow: hidden;
  overflow-x: auto;
  
}
.nav-tabs .nav-link {
  border:0px;
  margin-bottom:-2px;
  margin-left:1px;
  color: #6c757d;
  font-weight: 600;
  background-color: #fff;
  border-color:#c0c0c0;
  border-top: 3px solid #cecece;
  min-width:82.25px;
  text-align:center;
}
.nav-tabs .nav-item{scroll-snap-align:end;}
.nav-tabs .nav-link:focus{
  border-top:3px solid #cecece;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus{
  background-color: #f0f0f0;
  color:#495057;
  isolation: unset;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  border-top:3px solid #6c757d;
  isolation: isolate;
}
.nav-tabs .add-tab{
  position:absolute;
  right:1px;
  background-color: #6c757d;
  color: white;
}
.nav-tabs .add-tab:hover,.nav-tabs .add-tab:focus{
  color: #6c757d;
}

.nav-tabs .total-tab:hover{
  color: #6c757d;
}

.simplebar-content{
  display: flex;
  flex-wrap: nowrap;
  -webkit-scroll-snap-type: x mandatory;-ms-scroll-snap-type: x mandatory;scroll-snap-type: x mandatory;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar{
  top: 4px !important;
}

.removePort{
  padding: 10px;
  width: 100%;
  max-width: 450px;
  border: 1px dashed rgba(0,0,0,.2);
}